import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import {AutosizeModule} from 'ngx-autosize';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { VideoService } from './services/video.service';
import { VideoControlsComponent } from './components/video-controls/video-controls.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoModule } from './modules/video.module';
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios'
  }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideDatabase(() => getDatabase()),
    provideMessaging(() => getMessaging()),
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    AutosizeModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AppComponent],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AngularFireDatabase,VideoService],
  bootstrap: [AppComponent]
})
export class AppModule {}
