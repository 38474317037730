import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private videoSource = new BehaviorSubject<string>('https://babelline.digital-vi.be/assets/videos/video-1.mp4');
  currentVideo = this.videoSource.asObservable();

  changeVideo(newVideo: string) {
    this.videoSource.next(newVideo);
  }
}