// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCR1wtEzUDW8mMrhb9jzxzLU4O_1YXQuxk",
    authDomain: "interactive-product-video.firebaseapp.com",
    databaseURL: "https://interactive-product-video-default-rtdb.firebaseio.com",
    projectId: "interactive-product-video",
    storageBucket: "interactive-product-video.appspot.com",
    messagingSenderId: "189401269056",
    appId: "1:189401269056:web:3dda74b641ee6f1a0870a5",
    measurementId: "G-5BWJMVLW0W"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
